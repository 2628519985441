





































































































import Vue, { PropType } from 'vue';
import { IProcessedTreeNode } from '@/models/tree-node';
import TreeExpandArrow from '@/components/TreeExpandArrow.vue';
import TreeNodeIcon from '@/components/TreeNodeIcon.vue';
import { IFullTreeOptions } from '@/models/tree-options';
import isExpanded from '@/functions/tree/is-expanded';

interface IData {
    nodesToRender: IProcessedTreeNode[];
}

const TreeNode = Vue.extend({
  name: 'TreeNode',
  inject: ['emitTreeEvent'],
  components: {
    TreeExpandArrow,
    TreeNodeIcon,
  },
  props: {
    node: {
      type: Object as PropType<IProcessedTreeNode>,
      required: true,
    },
    options: {
      type: Object as PropType<IFullTreeOptions>,
      required: true,
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
    activeNodeId: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    isRecursive(): boolean {
      return !this.options.virtualScrolling.useVirtualScrolling;
    },
  },
  methods: {
    onCurrentNodeArrowClick(e: Event) {
      e.stopPropagation();
      this.emitTreeEvent('arrow-click', this.node);
      this.$emit('arrow-click', this.node);
    },
    onArrowClick(node: IProcessedTreeNode) {
      this.$emit('arrow-click', node);
    },
    onCurrentNodeClick() {
      this.emitTreeEvent('node-click', this.node);
      this.$emit('node-click', this.node);
    },
    onNodeClick(node: IProcessedTreeNode) {
      this.$emit('node-click', node);
    },
    isExpanded(node: IProcessedTreeNode) {
      return isExpanded(node);
    },
    isActive(node: IProcessedTreeNode) {
      return node.id === this.activeNodeId;
    },
    getLeftMargin(node: IProcessedTreeNode) {
      if (this.isRoot) {
        return '';
      }
      if (node.__depth === 1 || !this.options.virtualScrolling.useVirtualScrolling) {
        return '8px';
      }
      if (node.__depth && node.__depth > 1) {
        const dottedBorder = 7;
        const depthPadding = 24;
        return `${(node.__depth - 1) * depthPadding + dottedBorder}px`;
      }
      return '';
    },
  },
});

export default TreeNode;
