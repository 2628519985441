var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.options.virtualScrolling.enableVariableSize)?_c('dynamic-scroller',{staticClass:"virtual-scroller",attrs:{"items":_vm.items,"min-item-size":_vm.options.virtualScrolling.itemSize,"key-field":_vm.options.virtualScrolling.vueVirtualScrollerOptions.keyField,"direction":_vm.options.virtualScrolling.vueVirtualScrollerOptions.direction,"buffer":_vm.options.virtualScrolling.vueVirtualScrollerOptions.buffer,"size-field":_vm.options.virtualScrolling.vueVirtualScrollerOptions.sizeFiled,"type-field":_vm.options.virtualScrolling.vueVirtualScrollerOptions.typeField,"page-mode":_vm.options.virtualScrolling.vueVirtualScrollerOptions.pageMode,"prerender":_vm.options.virtualScrolling.vueVirtualScrollerOptions.prerender,"emit-update":_vm.options.virtualScrolling.vueVirtualScrollerOptions.emitUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
var active = ref.active;
return [_c('dynamic-scroller-item',{attrs:{"item":item,"active":active,"size-dependencies":[
        item.name ],"data-index":index}},[_vm._t("default",null,{"item":item,"index":index,"active":active})],2)]}}],null,true)}):_c('recycle-scroller',{staticClass:"virtual-scroller",attrs:{"items":_vm.items,"item-size":_vm.options.virtualScrolling.itemSize,"key-field":_vm.options.virtualScrolling.vueVirtualScrollerOptions.keyField,"direction":_vm.options.virtualScrolling.vueVirtualScrollerOptions.direction,"buffer":_vm.options.virtualScrolling.vueVirtualScrollerOptions.buffer,"size-field":_vm.options.virtualScrolling.vueVirtualScrollerOptions.sizeFiled,"type-field":_vm.options.virtualScrolling.vueVirtualScrollerOptions.typeField,"page-mode":_vm.options.virtualScrolling.vueVirtualScrollerOptions.pageMode,"prerender":_vm.options.virtualScrolling.vueVirtualScrollerOptions.prerender,"emit-update":_vm.options.virtualScrolling.vueVirtualScrollerOptions.emitUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
return [_vm._t("default",null,{"item":item})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }