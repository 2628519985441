var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tree-node transition-border min-h-5 focus:outline-none",class:{
    'border-l border-dashed border-gray-500': _vm.options.visual.showFolderBorders && !_vm.isRoot,
    'pb-1': _vm.isExpanded(_vm.node),
  },style:({
    paddingLeft: !_vm.isRoot ? '12px' : '',
    marginLeft: _vm.getLeftMargin(_vm.node)
  }),attrs:{"id":_vm.node.id,"data-test":"tree-node","tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.onCurrentNodeArrowClick($event)},"click":function($event){$event.stopPropagation();return _vm.onCurrentNodeClick($event)}}},[_c('div',{staticClass:"tree-node-content py-3 md:py-1 transition-bg leading-tight\n          flex flex-auto items-center cursor-pointer ",class:{
      'bg-indigo-200': _vm.isActive(_vm.node)
    }},[_c('tree-expand-arrow',{attrs:{"node":_vm.node,"options":_vm.options},on:{"click":_vm.onCurrentNodeArrowClick}}),_vm._t("nodeContent",[_vm._t("nodePrependLabel",null,{"nodeData":_vm.node}),_vm._t("nodeIcon",[_c('tree-node-icon',{staticClass:"tree-node-icon",attrs:{"node":_vm.node,"options":_vm.options}})],{"nodeData":_vm.node}),_vm._t("nodeLabel",[_c('div',{staticClass:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.node.name || _vm.node.id)}})])],{"nodeData":_vm.node})],{"nodeData":_vm.node})],2),(_vm.isRecursive && _vm.isExpanded(_vm.node))?_vm._l((_vm.node.children),function(child){return _c('tree-node',{key:child.id,attrs:{"node":child,"options":_vm.options,"is-root":false,"active-node-id":_vm.activeNodeId,"data-test":"tree-node"},on:{"arrow-click":_vm.onArrowClick,"node-click":_vm.onNodeClick},scopedSlots:_vm._u([(_vm.$slots.nodeContent)?{key:"nodeContent",fn:function(){return undefined},proxy:true}:null],null,true)},[(!_vm.$slots.nodeContent)?[_vm._t("nodePrependLabel",null,{"nodeData":_vm.node})]:_vm._e(),(!_vm.$slots.nodeContent)?[_vm._t("nodeLabel",null,{"nodeData":_vm.node})]:_vm._e()],2)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }