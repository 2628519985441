









import Vue from 'vue';

export default Vue.extend({
  name: 'PvtLoadingIndicator',
  props: {
    size: {
      type: Number,
      default: 42,
    },
  },
});
