var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.options.virtualScrolling.useVirtualScrolling)?_c('tree-virtual-scroller',{staticClass:"tree-wrapper",style:({maxHeight: _vm.treeHeight}),attrs:{"items":_vm.renderedTree,"options":_vm.options,"data-test":"virtual-tree-wrapper"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(item)?_c('tree-node',{attrs:{"node":item,"options":_vm.options,"is-root":item.__depth === 0,"active-node-id":_vm.activeNodeId,"data-test":"virtual-tree-wrapper-node"},on:{"arrow-click":_vm.onArrowClick,"node-click":_vm.onNodeClick},scopedSlots:_vm._u([{key:"nodeContent",fn:function(ref){
var nodeData = ref.nodeData;
return [_vm._t("nodeContent",null,{"nodeData":nodeData})]}},{key:"nodePrependLabel",fn:function(ref){
var nodeData = ref.nodeData;
return [_vm._t("nodePrependLabel",null,{"nodeData":nodeData})]}},{key:"nodeLabel",fn:function(ref){
var nodeData = ref.nodeData;
return [_vm._t("nodeLabel",null,{"nodeData":nodeData})]}}],null,true)}):_vm._e()]}}],null,false,2797047017)}):_c('div',{staticClass:"tree-wrapper",attrs:{"data-test":"tree-wrapper"}},_vm._l((_vm.renderedTree),function(node){return _c('tree-node',{key:node.id,attrs:{"node":node,"options":_vm.options,"is-root":node.__depth === 0,"active-node-id":_vm.activeNodeId,"data-test":"tree-wrapper-node"},on:{"arrow-click":_vm.onArrowClick,"node-click":_vm.onNodeClick},scopedSlots:_vm._u([{key:"nodeContent",fn:function(ref){
var nodeData = ref.nodeData;
return [_vm._t("nodeContent",null,{"nodeData":nodeData})]}},{key:"nodePrependLabel",fn:function(ref){
var nodeData = ref.nodeData;
return [_vm._t("nodePrependLabel",null,{"nodeData":nodeData})]}},{key:"nodeLabel",fn:function(ref){
var nodeData = ref.nodeData;
return [_vm._t("nodeLabel",null,{"nodeData":nodeData})]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }