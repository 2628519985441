
































































import { IFullTreeOptions } from '@/models/tree-options';
import Vue, { PropType } from 'vue';
import { DynamicScroller, DynamicScrollerItem, RecycleScroller } from 'vue-virtual-scroller';

export default Vue.extend({
  name: 'RcDynamicScroller',
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    RecycleScroller,
  },
  props: {
    /**
     * Array of items to be iterated over
     */
    items: {
      type: Array,
      required: true,
    },
    options: {
      type: Object as PropType<IFullTreeOptions>,
      required: true,
    },
  },
});
