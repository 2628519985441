


























































import Vue from 'vue';
import PvtClarityIcon from '@/components/support/PvtClarityIcon.vue';

export default Vue.extend({
  name: 'PvtVerticalAccordion',
  components: {
    PvtClarityIcon,
  },
  inject: ['emitTreeEvent'],
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: true,
    },
    preserveHidden: {
      type: Boolean,
      default: true,
    },
    expandDirection: {
      type: String,
      default: 'right',
      validator: (val: string) => ['left', 'right'].includes(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /** If preserveHidden is set to false, the content is controlled by v-if. Otherwise by v-show */
    preserveHiddenContent(): boolean {
      if (this.preserveHidden) {
        return true;
      }
      return !this.value;
    },
    isCollapsed: {
      get(): boolean { return this.value; },
      set(val: boolean): void { this.$emit('input', val); },
    },
  },
  methods: {
    onCollapse() {
      this.emitTreeEvent('accordion-collapsed');
      this.isCollapsed = true;
    },
    onExpand() {
      this.emitTreeEvent('accordion-expanded');
      this.isCollapsed = false;
    },
  },
});
