











import Vue from 'vue';

export default Vue.extend({
  name: 'PvtInput',
  components: {
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    inputValue: {
      get(): string { return this.localValue; },
      set(value: string): void {
        this.localValue = value;
        this.$emit('input', value);
      },
    },
  },
});
